//simple preloader spinner

export function Loader() {
	return (
		<div id='preloader'>
			<div id='loader'></div>
			<div id='loader-logo'>
				<img width='66px' src='/images/logo-q.png'></img>{' '}
			</div>
		</div>
	);
}
