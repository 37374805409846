import { motion } from 'framer-motion';
import { uiState } from '../../states/ui-state.js';
import useSound from 'use-sound';
const Backdrop = (props) => {
	const clickSound = '../audio/nav-click.mp3';
	const moveSound = '../audio/menu-start.mp3';

	const [play] = useSound(clickSound, { volume: 0.08 });
	const [play2] = useSound(moveSound, { playbackRate: 3.0, volume: 0.02 });

	const handleClick = (e, state) => {
		e.stopPropagation();
		uiState.customLabelUIShow = false;
		uiState.homeReveal = false;
		uiState.optinShow = false;
		play();
		// play2();
	};

	const clickIt = props.clickActive;
	let cursorStyle = 'pointer';

	clickIt ? (cursorStyle = 'pointer') : (cursorStyle = 'auto');

	return (
		<motion.div
			className={props.cssName}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			onClick={(e) => handleClick(e, props.clickTarget)}
			style={{ cursor: clickIt }}
		>
			{props.children}
		</motion.div>
	);
};

export default Backdrop;
