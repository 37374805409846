import React, { useRef, useEffect, useState } from 'react';
import { useGLTF } from '@react-three/drei';
import { RigidBody, Physics } from '@react-three/rapier';
import useSound from 'use-sound';
import { uiState } from '../states/ui-state.js';
import { useNavigate } from 'react-router-dom';

//TheatreJS Animation UI
import { getProject, val, types } from '@theatre/core';
import { editable as e } from '@theatre/r3f';
import QuestProjectState from '../states/state.json';
const project = getProject('Quest Project', {
	state: QuestProjectState,
});
const QuestSheet = project.sheet('Quest Sheet');

export function Office1(props) {
	const instancedDesk1 = useRef();
	const { nodes, materials } = useGLTF('/models/office1-optimized.glb');

	const [hovered, setHovered] = useState(false);
	const over = (e) => (e.stopPropagation(), setHovered(true));
	const out = () => setHovered(false); //Setup Mouse hover
	useEffect(() => {
		if (hovered) document.body.style.cursor = 'pointer';
		return () => (document.body.style.cursor = 'auto');
	}, [hovered]);
	const navigate = useNavigate();

	const clickSound = '../audio/nav-click.mp3';
	const moveSound = '../audio/menu-start.mp3';

	const [play] = useSound(clickSound, { volume: 0.08 });
	const [play2] = useSound(moveSound, { playbackRate: 3.0, volume: 0.02 });

	const handleClick = (e, state) => {
		e.stopPropagation();
		uiState.animReady = true;
		if (state != uiState.animLocation) {
			navigate(state);
			play();
			play2();
		}
	};

	return (
		// <Physics>
		<group {...props} dispose={null}>
			<mesh
				// visible={!uiState.isMobile}
				name='books_pile'
				castShadow
				receiveShadow
				geometry={nodes.books_pile.geometry}
				material={materials.M_books}
				position={[11.469, 2.706, -2.448]}
				rotation={[Math.PI / 2, 0, 0]}
				scale={0.084}
			/>
			<mesh
				// visible={!uiState.isMobile}
				name='book2'
				castShadow
				receiveShadow
				geometry={nodes.book2.geometry}
				material={materials.M_books}
				position={[7.163, 1.764, -3.783]}
				rotation={[Math.PI / 2, 0, -2.987]}
				scale={0.084}
			/>
			<mesh
				// visible={!uiState.isMobile}
				name='bookshelf'
				castShadow
				receiveShadow
				geometry={nodes.bookshelf.geometry}
				material={materials.M_bookshelf}
				position={[11.582, 2.24, -2.396]}
				rotation={[Math.PI / 2, 0, 0]}
				scale={0.712}
			/>
			{/* <RigidBody> */}
			<mesh
				name='desk'
				castShadow
				receiveShadow
				geometry={nodes.desk.geometry}
				material={materials.M_desk}
				position={[6.254, 0.885, -3.692]}
				rotation={[0, -1.438, 0]}
				scale={0.736}
			/>
			{/* </RigidBody> */}
			<e.group theatreKey={'Quest / Scene / Desk 2'}>
				{/* <RigidBody> */}
				<mesh
					// visible={!uiState.isMobile}
					name='desk2'
					castShadow
					receiveShadow
					geometry={nodes.desk.geometry}
					material={materials.M_desk}
					position={[6.254, 0.885, -3.692]}
					rotation={[0, -1.438, 0]}
					scale={0.736}
				/>
				{/* </RigidBody> */}
			</e.group>

			<mesh
				name='door'
				castShadow
				receiveShadow
				geometry={nodes.door.geometry}
				material={materials.M_door}
				position={[12.292, 1.76, -5.156]}
				rotation={[Math.PI / 2, 0, 0]}
				scale={1.458}
			/>
			<mesh
				name='filecabinet1'
				castShadow
				receiveShadow
				geometry={nodes.filecabinet1.geometry}
				material={materials.M_filecabinet}
				position={[10.295, 1.269, -1.591]}
				rotation={[Math.PI / 2, 0, 1.538]}
				scale={0.406}
			/>
			<mesh
				name='filecabinet3'
				castShadow
				receiveShadow
				geometry={nodes.filecabinet3.geometry}
				material={materials.M_filecabinet}
				position={[10.944, 1.269, -9.073]}
				rotation={[Math.PI / 2, 0, 0]}
				scale={0.406}
			/>
			<mesh
				name='book_pile_1'
				castShadow
				receiveShadow
				geometry={nodes.book_pile_1.geometry}
				material={materials.M_books}
				position={[6.362, 1.877, -5.467]}
				rotation={[Math.PI / 2, 0, -0.129]}
				scale={0.084}
			/>
			<mesh
				name='filefolder_1'
				castShadow
				receiveShadow
				geometry={nodes.filefolder_1.geometry}
				material={materials.M_filefolders}
				position={[10.198, 2.705, -0.966]}
				rotation={[Math.PI / 2, 0, 0.513]}
				scale={0.231}
			/>
			<mesh
				name='filefolder_open'
				castShadow
				receiveShadow
				geometry={nodes.filefolder_open.geometry}
				material={materials.M_filefolders}
				position={[6.417, 1.777, -3.887]}
				rotation={[Math.PI / 2, 0, -0.194]}
				scale={0.231}
				onClick={(e) => handleClick(e, 'thankyou')}
				onPointerOver={over}
				onPointerOut={out}
			/>
			<mesh
				// visible={!uiState.isMobile}
				name='box3'
				castShadow
				receiveShadow
				geometry={nodes.box3.geometry}
				material={materials.M_cardboxes}
				position={[9.931, 0.239, -7.795]}
				rotation={[Math.PI / 2, 0, -1.054]}
				scale={0.804}
			/>
			<mesh
				name='book1'
				castShadow
				receiveShadow
				geometry={nodes.book1.geometry}
				material={materials.M_books}
				position={[5.967, 1.76, -5.051]}
				rotation={[Math.PI / 2, 0, -0.129]}
				scale={0.084}
			/>
			<mesh
				// visible={!uiState.isMobile}
				name='filefolders_pile'
				castShadow
				receiveShadow
				geometry={nodes.filefolders_pile.geometry}
				material={materials.M_filefolders}
				position={[10.329, 1.502, -1.623]}
				rotation={[0, 0.029, Math.PI / 2]}
				scale={0.231}
			/>
			<mesh
				name='COMP2'
				castShadow
				receiveShadow
				geometry={nodes.COMP2.geometry}
				material={materials['Material #29']}
				position={[8.736, 0.74, -8.983]}
				rotation={[0, 1.267, 0]}
				scale={[0.011, 0.01, 0.01]}
			/>
			<mesh
				// visible={!uiState.isMobile}
				name='window2'
				// castShadow
				// receiveShadow
				geometry={nodes.window2.geometry}
				material={materials.M_window}
				position={[7.222, 2.833, -0.235]}
				rotation={[Math.PI / 2, 0, Math.PI / 2]}
				scale={1.457}
			/>
			<mesh
				// visible={!uiState.isMobile}
				name='window1'
				// castShadow
				// receiveShadow
				geometry={nodes.window1.geometry}
				material={materials.M_window}
				position={[3.775, 2.833, -0.235]}
				rotation={[Math.PI / 2, 0, Math.PI / 2]}
				scale={1.457}
			/>
		</group>
		// </Physics>
	);
}

useGLTF.preload('/models/office1-optimized.glb');
