import React, { useRef } from 'react';
import {
	Float,
	Center,
	Image,
	Text3D,
	useMatcapTexture,
} from '@react-three/drei';
//TheatreJS Animation UI
import { getProject, val, types } from '@theatre/core';
import studio from '@theatre/studio';
import extension from '@theatre/r3f/dist/extension';
import { editable as e, SheetProvider } from '@theatre/r3f';
import QuestProjectState from '../states/state.json';

export function Logo(props) {
	const project = getProject('Quest Project', {
		state: QuestProjectState,
	});
	const QuestSheet = project.sheet('Quest Sheet');
	//TODO this uses 3rd party CDN for this type of texture, fix it for production
	//flat gold
	const [matcapTextureGold] = useMatcapTexture(
		'71623B_ECDE8C_30250A_ABA69A',
		64
	);
	const [matcapTextureGlass] = useMatcapTexture(
		'293534_B2BFC5_738289_8A9AA7',
		64
	);
	const [matcapTextureChrome] = useMatcapTexture(
		'736655_D9D8D5_2F281F_B1AEAB',
		64
	);
	//chrome
	const [matcapTextureChrome2] = useMatcapTexture(
		'54584E_B1BAC5_818B91_A7ACA3',
		64
	);
	//soft dark purple
	const [matcapTexturePurple] = useMatcapTexture(
		'5C045C_BD0DBD_930493_A404A4',
		64
	);
	//soft dark purple
	const [matcapTextureShinyGreen] = useMatcapTexture(
		'2E763A_78A0B7_B3D1CF_14F209',
		64
	);
	return (
		<SheetProvider sheet={QuestSheet}>
			<group {...props} dispose={null}>
				<Float
					speed={2.0} // Animation speed, defaults to 1
					// rotationIntensity={0.1} // XYZ rotation intensity, defaults to 1
					// floatIntensity={0.02} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
					// floatingRange={[0, 0.5]}
				>
					<Center top left>
						{/* LOGO Q IMAGE */}
						<e.group
							theatreKey={'Logo / Q Image'}
							scale={[1.1, 1.1, 1.1]}
							position={[0, 0.065, 0.077]}
							rotation={[-1.571, 0, 0]}
						>
							<Image
								receiveShadow
								// scale={[1, 1, 1]}
								url={'/images/logo-q-888.png'}
								transparent
							/>
						</e.group>
						<e.group theatreKey='Logo / The'>
							<Text3D
								font='./fonts/Doctor_Glitch.json'
								size={0.5}
								height={0.3}
								bevelEnabled={true}
								bevelThickness={0.006}
								bevelSize={0.006}
								bevelSegments={8}
							>
								THE{' '}
								<meshMatcapMaterial
									matcap={matcapTexturePurple}
								/>
							</Text3D>
						</e.group>
						<e.group theatreKey='Logo / Q'>
							<Text3D
								font='./fonts/Doctor_Glitch.json'
								size={0.75}
								height={0.3}
								bevelEnabled={true}
								bevelThickness={0.006}
								bevelSize={0.006}
								bevelSegments={8}
							>
								Q
								<meshMatcapMaterial
									matcap={matcapTexturePurple}
								/>
							</Text3D>
						</e.group>
						<e.group theatreKey='Logo / uest'>
							<Text3D
								font='./fonts/Doctor_Glitch.json'
								size={0.5}
								height={0.3}
								bevelEnabled={true}
								bevelThickness={0.006}
								bevelSize={0.006}
								bevelSegments={8}
							>
								UEST
								<meshMatcapMaterial
									matcap={matcapTexturePurple}
								/>
							</Text3D>
						</e.group>
					</Center>
				</Float>
			</group>
		</SheetProvider>
	);
}
