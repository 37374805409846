import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { uiState } from '../states/ui-state.js';
import { useSnapshot } from 'valtio';
import { motion } from 'framer-motion';
import Backdrop from './Backdrop';
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';

const OptinForm = (props) => {
	const [emailState, setEmail] = useState('');
	const [errorShow, setError] = useState('');
	const [buttonDisabled, setbuttonDisabled] = useState(false);
	const [buttonLabel, setbuttonLabel] = useState('LEARN MORE');
	const snap = useSnapshot(uiState);
	const [hovered, setHovered] = useState(false);
	const over = (e) => (e.stopPropagation(), setHovered(true));
	const out = () => setHovered(false); //Setup Mouse hover
	useEffect(() => {
		if (hovered) document.body.style.cursor = 'pointer';
		return () => (document.body.style.cursor = 'auto');
	}, [hovered]);

	const clickSound = '../audio/nav-click.mp3';
	const moveSound = '../audio/menu-start.mp3';

	const [play] = useSound(clickSound, { volume: 0.08 });
	const [play2] = useSound(moveSound, { playbackRate: 3.0, volume: 0.02 });

	const handleClick = (e, state) => {
		e.stopPropagation();
		uiState.optinShow = false;
		play();
	};

	const validateEmail = (email) => {
		const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		return regex.test(email);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setbuttonDisabled(true);
		setbuttonLabel('SENDING...');
		if (validateEmail(emailState)) {
			// Create a new FormData object
			const formData = new FormData();
			formData.append('email', emailState);

			// Send the FormData object with Axios to the webhook
			try {
				//try creating new contact
				const response = await axios.post(
					'https://watchers.app.n8n.cloud/webhook/6f7b288e-1efe-4504-a6fd-660931327269',
					// 'https://watchers.app.n8n.cloud/webhook-test/6f7b288e-1efe-4504-a6fd-660931327269',
					formData
				);
				console.log('Response:', response.data);
				setError('');
				// setEmail('Enter valid email');
				navigate('thankyou');
			} catch (error) {
				setError('api');
				console.error('Error sending data:', error);
				//send to different webhook if fails to create new lead (this means the email exists already and needs an update method called)
				const response = await axios.post(
					'https://watchers.app.n8n.cloud/webhook/e85f2b3f-137b-4a4b-99eb-579dc340b747',
					formData
				);
				console.log('Response:', response.data);
				setbuttonDisabled(false);
				setbuttonLabel('THANK YOU');
				navigate('thankyou');
				setbuttonLabel('LEARN MORE');
				play2();
			}
		} else {
			setError('email');
			setbuttonDisabled(false);
			console.error('Email Not Valid:');
		}
	};

	const navigate = useNavigate();

	return (
		<div hidden={!uiState.optinShow}>
			<Backdrop cssName='optin-backdrop' />
			<div className='container'>
				<div
					className='optin-close'
					onClick={(e) => handleClick(e, 'front')}
				>
					<div
						style={{
							position: 'absolute',
							top: '.41rem',
							left: '.61rem',
						}}
					>
						✕
					</div>
				</div>
				<div className='cardheaderBG' style={{ position: 'relative' }}>
					<img
						src='/images/logo-wide.png'
						style={{
							width: '100%',
							position: 'relative',
							top: '-16px',
							left: '3px',
						}}
					/>
				</div>
				<div style={{ position: 'relative' }}>
					<img
						src='/images/shallweplayagame.gif'
						style={{
							width: '100%',
							position: 'relative',
							top: '-33px',
							left: '0',
							right: '0',
							marginLeft: 'auto',
							marginRight: 'auto',
							borderRadius: '8px',
						}}
					/>
					<div
						style={{
							color: '#fff',
							fontWeight: '400',
							fontSize: '1.5rem',
							textAlign: 'center',
							position: 'relative',
							top: '-35px',
							left: '0',
							paddingBottom: '0px',
						}}
					>
						<br />
						AI wants to control you.
						<br />
						{/* <br /> */}
						Fame and fortune await.
						<br />
						<br />
						Enter your best email:
					</div>
				</div>
				<form onSubmit={handleSubmit}>
					<input
						type='email'
						value={emailState}
						onChange={(e) => setEmail(e.target.value)}
						placeholder='Enter valid email'
						// onBlur={handleBlur}
						// className='input-error'
					/>
					{/* Errors */}
					{errorShow == 'email' && (
						<p className='error'>Enter valid email</p>
					)}
					<motion.button
						disabled={buttonDisabled}
						type='submit'
						whileHover={{ scale: 1.03 }}
						whileTap={{ scale: 0.99 }}
					>
						<span
							// className='gradient-text-gold'
							style={{
								color: '#ccc',
								fontWeight: '700',
							}}
							className='gradient-text'
						>
							{buttonLabel}
						</span>
					</motion.button>
				</form>
			</div>
		</div>
	);
};
export default OptinForm;
