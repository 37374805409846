import React, { useRef, useEffect, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import * as THREE from 'three';
import Rotate from './Rotate';
// import { RigidBody, Physics } from '@react-three/rapier';
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';
import { uiState } from '../states/ui-state.js';

//TheatreJS Animation UI
import { getProject, val, types } from '@theatre/core';
import { editable as e, SheetProvider } from '@theatre/r3f';
import QuestProjectState from '../states/state.json';
const project = getProject('Quest Project', {
	state: QuestProjectState,
});
// const PhoneSheet = project.sheet('Phone Sheet');

export function Office2(props) {
	const group = useRef();
	const { nodes, materials, animations } = useGLTF(
		'/models/office2-optimized.glb'
	);

	const [hovered, setHovered] = useState(false);
	const over = (e) => (e.stopPropagation(), setHovered(true));
	const out = () => setHovered(false); //Setup Mouse hover
	useEffect(() => {
		if (hovered) document.body.style.cursor = 'pointer';
		return () => (document.body.style.cursor = 'auto');
	}, [hovered]);
	const navigate = useNavigate();

	//add animations
	const { ref, actions, names } = useAnimations(animations, group);
	// let mixer = null;
	// mixer = new THREE.AnimationMixer(scene);
	// void mixer.clipAction(animations[0]).play();
	// void mixer.clipAction(animations[1]).play();
	// useFrame((state, delta) => {
	// 	mixer.update(delta);
	// 	// console.log(ca);
	// });
	useEffect(() => {
		console.log('anim names', names);
		actions[names[10]].setLoop(1, 100).reset().play();
		// actions[names[10]].playAt(20, 30);
	}, []);

	// let mixer = null;
	// mixer = new THREE.AnimationMixer(scene);
	// void mixer.clipAction(animations[0]).play();
	// void mixer.clipAction(animations[1]).play();
	// useFrame((state, delta) => {
	// 	mixer.update(delta);
	// 	// console.log(ca);
	// });

	//add sounds
	const clickSound = '../audio/nav-click.mp3';
	const moveSound = '../audio/menu-start.mp3';

	const [play] = useSound(clickSound, { volume: 0.08 });
	const [play2] = useSound(moveSound, { playbackRate: 3.0, volume: 0.02 });

	const handleClick = (e, state) => {
		e.stopPropagation();
		uiState.animReady = true;
		if (state != uiState.animLocation) {
			navigate(state);
			play();
			play2();
		}
	};

	return (
		<group ref={ref} {...props} dispose={null}>
			<group name='Scene'>
				<e.group theatreKey={'Quest / Scene / Jambox'}>
					<mesh
						// visible={!uiState.isMobile}
						// onClick={(e) => handleRadioClick(e)}
						// onPointerOver={over}
						// onPointerOut={out}
						name='RECORDER'
						castShadow
						receiveShadow
						geometry={nodes.RECORDER.geometry}
						material={materials['Material #29']}
						position={[4.864, 0.08, -8.784]}
						rotation={[0.023, 0.959, -0.022]}
						scale={[0.009, 0.009, 0.007]}
					/>
				</e.group>
				<e.group theatreKey={'Quest / Scene / Ceiling Fan'}>
					<Rotate axis={'z'} speed={3.3}>
						<mesh
							// visible={!uiState.isMobile}
							name='ceilingfan-blades'
							castShadow
							receiveShadow
							geometry={nodes['ceilingfan-blades'].geometry}
							material={materials.M_ceilingfan}
							scale={0.524}
						/>
					</Rotate>
				</e.group>
				<mesh
					// visible={!uiState.isMobile}
					name='filefolders_pile2'
					castShadow
					receiveShadow
					geometry={nodes.filefolders_pile2.geometry}
					material={materials.M_filefolders}
					position={[11.441, 1.199, -2.364]}
					rotation={[Math.PI / 2, 0, 0.051]}
					scale={0.231}
				/>
				{/* <RigidBody type='fixed'> */}
				<mesh
					name='floor'
					// castShadow
					receiveShadow
					geometry={nodes.floor.geometry}
					material={materials.M_floor}
					position={[6.287, 0.119, -6.378]}
					rotation={[Math.PI / 2, 0, 0]}
					scale={1.139}
				/>
				{/* </RigidBody> */}
				{/* <RigidBody> */}
				<mesh
					// visible={!uiState.isMobile}
					name='foldingchair2'
					castShadow
					receiveShadow
					geometry={nodes.foldingchair2.geometry}
					material={materials.M_foldingchair}
					position={[8.584, 0.866, -7.934]}
					rotation={[Math.PI / 2, 0, -0.659]}
					scale={0.408}
				/>
				{/* </RigidBody> */}

				<mesh
					// visible={!uiState.isMobile}
					name='hanger'
					castShadow
					receiveShadow
					geometry={nodes.hanger.geometry}
					material={materials.M_hanger}
					position={[11.648, 2.127, -3.984]}
					rotation={[Math.PI / 2, 0, 1.141]}
					scale={0.358}
				/>
				{/* empty animation object for dial_1 */}

				<group
					name='Dialer'
					position={[6.714, 1.947, -2.218]}
					scale={0.055}
				/>
				{/* <SheetProvider sheet={PhoneSheet}> */}
				{/* <e.group theatreKey={'Phone Dial'}> */}
				<mesh
					name='dial_1'
					geometry={nodes.dial_1.geometry}
					material={materials.Part_Material}
					position={[6.827, 1.947, -2.359]}
					rotation={[-0.23, -0.242, 0.718]}
					scale={0.021}
					// rotation-y={(Math.PI / 180) * 45}
				/>
				{/* </e.group> */}
				{/* </SheetProvider> */}

				<group
					name='old_phone'
					position={[6.988, 1.805, -2.277]}
					rotation={[Math.PI / 2, 0, 2.042]}
					scale={0.021}
					onClick={(e) => handleClick(e, 'phone')}
					onPointerOver={over}
					onPointerOut={out}
				>
					<mesh
						name='body'
						geometry={nodes.body.geometry}
						material={materials.Korpys_Material}
						position={[-0.026, 2.59, -4.058]}
					/>
					<mesh
						name='dial_2'
						geometry={nodes.dial_2.geometry}
						material={materials.Part_Material}
						position={[0.672, 8.034, -6.179]}
					/>
					<mesh
						name='dial_3'
						geometry={nodes.dial_3.geometry}
						material={materials.Part_Material}
						position={[-0.001, 8.702, -6.992]}
						rotation={[Math.PI / 2, 1.257, -Math.PI]}
					/>
					<mesh
						name='handset'
						geometry={nodes.handset.geometry}
						material={materials.Korpys_Material}
						position={[-1.94, 0.002, -8.711]}
					/>
					<mesh
						name='wire'
						geometry={nodes.wire.geometry}
						material={materials.Part_Material}
						position={[-12.901, -5.624, 1.447]}
					/>
				</group>

				<mesh
					// visible={!uiState.isMobile}
					name='hat'
					castShadow
					receiveShadow
					geometry={nodes.hat.geometry}
					material={materials.M_hat}
					position={[11.162, 3.257, -3.748]}
					rotation={[2.33, 0.929, 0.743]}
					scale={0.241}
				/>
				{/* <RigidBody> */}
				<mesh
					// visible={!uiState.isMobile}
					name='chair-main'
					castShadow
					receiveShadow
					geometry={nodes['chair-main'].geometry}
					material={materials.M_mainchair}
					position={[5.108, 0.759, -3.588]}
					rotation={[Math.PI / 2, 0, -1.83]}
					scale={0.542}
				/>
				{/* </RigidBody> */}

				<mesh
					name='mug'
					castShadow
					receiveShadow
					geometry={nodes.mug.geometry}
					material={materials.M_mug}
					position={[6.759, 1.823, -4.34]}
					rotation={[Math.PI / 2, 0, 2.365]}
					scale={0.086}
				/>
				<mesh
					name='noteboard_big'
					// castShadow
					receiveShadow
					geometry={nodes.noteboard_big.geometry}
					material={materials.M_noteboard}
					position={[8.994, 3.282, -10.513]}
					rotation={[Math.PI / 2, 0, -Math.PI / 2]}
					scale={1.437}
				/>
				<mesh
					name='noteboard_other'
					// castShadow
					receiveShadow
					geometry={nodes.noteboard_other.geometry}
					material={materials.M_noteboard}
					position={[9.39, 2.441, -0.243]}
					rotation={[Math.PI / 2, 0, Math.PI / 2]}
				/>
				<mesh
					name='notebook'
					geometry={nodes.notebook.geometry}
					material={materials.M_filefolders}
					position={[6.864, 1.795, -2.843]}
					rotation={[1.603, 0.043, 0.932]}
					scale={0.231}
				/>
				<mesh
					name='paperbydoor'
					// castShadow
					receiveShadow
					geometry={nodes.paperbydoor.geometry}
					material={materials.M_noteboard}
					position={[9.39, 2.441, -0.243]}
					rotation={[Math.PI / 2, 0, Math.PI / 2]}
					scale={1.437}
				/>
				<mesh
					name='papers'
					// castShadow
					receiveShadow
					geometry={nodes.papers.geometry}
					material={materials.M_noteboard}
					position={[6.396, 1.747, -2.003]}
					rotation={[Math.PI / 2, 0, Math.PI / 2]}
					scale={1.437}
					onClick={(e) => handleClick(e, 'thankyou')}
					onPointerOver={over}
					onPointerOut={out}
				/>
				<mesh
					name='pencil'
					// castShadow
					// receiveShadow
					geometry={nodes.pencil.geometry}
					material={materials.M_noteboard}
					position={[9.39, 2.441, -0.243]}
					rotation={[Math.PI / 2, 0, Math.PI / 2]}
					scale={1.437}
					onClick={(e) => handleClick(e, 'thankyou')}
					onPointerOver={over}
					onPointerOut={out}
				/>
				<mesh
					name='picframe'
					castShadow
					receiveShadow
					geometry={nodes.picframe.geometry}
					material={materials.M_picframe}
					position={[7.348, 1.915, -4.308]}
					rotation={[1.489, -0.218, -0.363]}
					scale={0.206}
					onClick={(e) => handleClick(e, 'thankyou')}
					onPointerOver={over}
					onPointerOut={out}
				/>
				<mesh
					name='radio'
					castShadow
					receiveShadow
					geometry={nodes.radio.geometry}
					material={materials.M_radio}
					position={[11.248, 2.693, -8.915]}
					rotation={[Math.PI / 2, 0, -0.406]}
					scale={0.362}
				/>
				<mesh
					name='safebox'
					castShadow
					receiveShadow
					geometry={nodes.safebox.geometry}
					material={materials.MT_safebox}
					position={[11.225, 0.726, -7.376]}
					rotation={[Math.PI / 2, 0, 1.582]}
					scale={0.062}
				/>
				<mesh
					name='tablefan'
					castShadow
					receiveShadow
					geometry={nodes.tablefan.geometry}
					material={materials.M_tablefan}
					position={[6.715, 2.285, -4.986]}
					rotation={[Math.PI / 2, 0, 0.633]}
					scale={0.175}
					onClick={(e) => handleClick(e, 'thankyou')}
					onPointerOver={over}
					onPointerOut={out}
				/>
				<mesh
					name='tablelamp'
					castShadow
					receiveShadow
					geometry={nodes.tablelamp.geometry}
					material={materials.M_tablelamp}
					position={[7.121, 2.389, -2.922]}
					rotation={[Math.PI / 2, 0, 0.976]}
					scale={0.197}
					onClick={(e) => handleClick(e, 'thankyou')}
					onPointerOver={over}
					onPointerOut={out}
				/>
				<mesh
					name='TV'
					castShadow
					receiveShadow
					geometry={nodes.TV.geometry}
					material={materials['Material #29']}
					position={[11.542, 1.501, -7.591]}
					rotation={[0, -1.098, 0]}
					scale={[0.011, 0.009, 0.005]}
					// onClick={(e) => handleClick(e, 'tv')}
					// onPointerOver={over}
					// onPointerOut={out}
				/>
				<mesh
					// visible={!uiState.isMobile}
					name='walllamp1'
					// castShadow
					// receiveShadow
					geometry={nodes.walllamp1.geometry}
					material={materials.M_walllamp}
					position={[6.024, 4.587, -10.235]}
					rotation={[Math.PI / 2, 0, 0]}
					scale={0.109}
				/>
				{/* <mesh
					// visible={!uiState.isMobile}
					name='walllamp2'
					// castShadow
					// receiveShadow
					geometry={nodes.walllamp2.geometry}
					material={materials.M_walllamp}
					position={[5.497, 4.5, -0.502]}
					rotation={[Math.PI / 2, 0, Math.PI]}
					scale={0.109}
				/> */}
				<mesh
					// visible={!uiState.isMobile}
					name='walllamp3'
					// castShadow
					// receiveShadow
					geometry={nodes.walllamp3.geometry}
					material={materials.M_walllamp}
					position={[11.98, 4.643, -7.619]}
					rotation={[1.831, 0, Math.PI / 2]}
					scale={0.109}
				/>
				<mesh
					// visible={!uiState.isMobile}
					name='walls'
					// castShadow
					receiveShadow
					geometry={nodes.walls.geometry}
					material={materials.M_walls}
					position={[6.51, 2.39, -5.189]}
					rotation={[Math.PI / 2, 0, 0]}
					scale={1.445}
				/>
				<mesh
					// visible={!uiState.isMobile}
					name='box2'
					castShadow
					receiveShadow
					geometry={nodes.box2.geometry}
					material={materials.M_cardboxes}
					position={[11.643, 2.785, -7.745]}
					rotation={[Math.PI / 2, 0, -1.787]}
				/>
				<mesh
					// visible={!uiState.isMobile}
					name='COMP1'
					castShadow
					receiveShadow
					geometry={nodes.COMP1.geometry}
					material={materials['Material #29']}
					position={[6.054, 2.175, -9.445]}
					rotation={[0, 0.079, 0]}
					scale={[0.011, 0.01, 0.01]}
				/>
				<mesh
					// visible={!uiState.isMobile}
					name='KEYBOARD'
					castShadow
					receiveShadow
					geometry={nodes.KEYBOARD.geometry}
					material={materials['Material #29']}
					position={[6.351, 1.779, -8.625]}
					rotation={[0, -0.048, 0]}
					scale={[0.011, 0.01, 0.01]}
				/>
				<mesh
					// visible={!uiState.isMobile}
					name='MONITOR'
					castShadow
					receiveShadow
					geometry={nodes.MONITOR.geometry}
					material={materials['Material #29']}
					position={[6.046, 2.166, -9.382]}
					rotation={[0, 0.052, 0]}
					scale={[0.011, 0.01, 0.01]}
				/>
				<mesh
					// visible={!uiState.isMobile}
					name='MOUSE'
					castShadow
					receiveShadow
					geometry={nodes.MOUSE.geometry}
					material={materials['Material #29']}
					position={[7.252, 1.785, -9.263]}
					rotation={[0, 0.184, 0]}
					scale={0.01}
				/>
				<mesh
					// visible={!uiState.isMobile}
					name='blinds'
					castShadow
					receiveShadow
					geometry={nodes.blinds.geometry}
					material={materials.M_blinds}
					position={[5.592, 3.336, -0.388]}
					rotation={[Math.PI / 2, 0, Math.PI / 2]}
				/>
				<mesh
					name='Ashtray'
					castShadow
					receiveShadow
					geometry={nodes.Ashtray.geometry}
					material={materials['01 - Default']}
					position={[6.571, 1.774, -3.067]}
				/>
				<mesh
					name='cigarette_box'
					castShadow
					receiveShadow
					geometry={nodes.cigarette_box.geometry}
					material={materials['01___Default']}
					position={[6.968, 1.783, -3.277]}
					rotation={[-0.202, 0, Math.PI]}
				/>
				<mesh
					// visible={!uiState.isMobile}
					name='ceiling'
					// castShadow
					// receiveShadow
					geometry={nodes.ceiling.geometry}
					material={materials.M_ceiling}
					position={[6.24, 5.461, -6.351]}
					rotation={[Math.PI / 2, 0, 0]}
				/>
				<mesh
					// visible={!uiState.isMobile}
					name='box10'
					castShadow
					receiveShadow
					geometry={nodes.box10.geometry}
					material={materials.M_cardboxes}
					position={[8.462, 1.078, -7.837]}
					rotation={[Math.PI / 2, 0, 1.399]}
					scale={0.924}
				/>
				<mesh
					// visible={!uiState.isMobile}
					name='ceilingfan-mount'
					castShadow
					receiveShadow
					geometry={nodes['ceilingfan-mount'].geometry}
					material={materials.M_ceilingfan}
					position={[8.162, 4.655, -5.25]}
					rotation={[Math.PI / 2, 0, 2.679]}
					scale={0.524}
				/>
			</group>
		</group>
	);
}

useGLTF.preload('/models/office2-optimized.glb');
