import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Backdrop from '../Backdrop';
import { uiState } from '../../states/ui-state.js';
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';

const Modal = ({ handleClose, text, label }) => {
	const [isOpen, setIsOpen] = useState(uiState.homeReveal);

	const clickSound = '../audio/nav-click.mp3';
	const moveSound = '../audio/menu-start.mp3';

	const [play] = useSound(clickSound, { volume: 0.08 });
	const [play2] = useSound(moveSound, { playbackRate: 3.0, volume: 0.02 });

	const navigate = useNavigate();

	const handleClick = (e, state) => {
		e.stopPropagation();
		navigate(state);
		uiState.clickedState = true;
		uiState.customLabelUIShow = false;
		uiState.homeReveal = false;
		uiState.homeShow = false;
		uiState.frontShow = 'video';
		setIsOpen(uiState.homeReveal);
		play();
		play2();
	};

	return (
		<>
			<Backdrop cssName='backdrop' clickIt={true} />
			<motion.div
				onClick={(e) => handleClick(e, 'learn')}
				className='home-ui'
			>
				<div className='cardheaderBG'>
					<img
						src='/images/logo-wide.png'
						style={{
							width: '333px',
							position: 'relative',
							top: '-17px',
							left: '6px',
						}}
					/>
				</div>
				<div
					style={{
						textAlign: 'center',
						color: '#ffffff',
						// fontWeight: 'bold',
						fontSize: '22px',
						position: 'relative',
						top: '10px',
						height: '296px',
						width: '350px',
					}}
				>
					<div
						className='gradient-text'
						style={{
							width: '333px',
							position: 'relative',
							left: '25px',
							top: '-3px',
							textAlign: 'left',
						}}
					>
						WILL YOU LET AI
						<br /> CONTROL YOU FOR <br />
						MONEY?
						<p
							className='gradient-text-red'
							style={{
								position: 'relative',
								left: '0px',
								top: '-30px',
								textAlign: 'left',
								color: '#570000',
								fontSize: '35px',
								position: 'relative',
								fontWeight: '700',
							}}
						>
							WATCHER?
							<br />
							OR <br />
							PLAYER?
						</p>
					</div>
					<img
						src='/images/key-right.png'
						style={{
							width: '380px',
							position: 'relative',
							top: '-381px',
							left: '35px',
						}}
					/>
				</div>

				<motion.button
					whileHover={{ scale: 1.1 }}
					whileTap={{ scale: 0.99 }}
					onClick={(e) => handleClick(e, 'learn')}
					className={'home-button'}
				>
					<span>{label} &nbsp;</span>
				</motion.button>
			</motion.div>
		</>
	);
};

export default Modal;
