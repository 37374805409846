import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { uiState, videoPlayerProxy } from '../../states/ui-state';
import { useLocation, useNavigate } from 'react-router-dom';
//TheatreJS Animation UI
import { getProject } from '@theatre/core';
import QuestProjectState from '../../states/state.json';

const project = getProject('Quest Project', {
	state: QuestProjectState,
});
const questSheet = project.sheet('Quest Sheet');

const AnimController = (props) => {
	const snap = useSnapshot(uiState);
	const location = useLocation();
	const navigate = useNavigate();

	//show hidden UI on load after Suspense
	useEffect(() => {
		uiState.homeShow = true;
		uiState.footerShow = true;
		//TODO logic if not cookie
		uiState.cookiePopShow = true;
	}, []);

	// UI Animation States
	useEffect(() => {
		console.log(
			'Anim Location:',
			uiState.animLocation,
			'\n',
			':',
			location.pathname
		);
		// uiState.homeShow = false;
		uiState.optinShow = false;
		uiState.customLabelUIShow = false;
		uiState.videoPlayerShow = false;
		// uiState.scrollControls = true;
		uiState.animEndUpdate = false;
		questSheet.project.ready.then(() => {
			const path = location.pathname.replace('/', '');
			if (
				path != uiState.animLocation &&
				location.pathname != uiState.animLocation
			) {
				uiState.animSpeed = 1;
				uiState.animLoop = 1;
				//pathname = where it's navigating TO
				switch (location.pathname) {
					//home /
					case '/':
						//animLocation = where it's navigating FROM
						switch (uiState.animLocation) {
							case '/':
								// uiState.homeShow = true;
								// uiState.mapControls = false;
								console.log('HOME CLICK');
								break;
							case 'learn':
								uiState.animStart = 0;
								uiState.animEnd = 1;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.6;
								break;
							case 'about':
								uiState.animStart = 3;
								uiState.animEnd = 4;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.6;
								break;
							case 'player':
								uiState.animStart = 12;
								uiState.animEnd = 13;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.5;
								break;
							case 'watcher':
								uiState.animStart = 10;
								uiState.animEnd = 11;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.7;
								break;
							case 'thankyou':
								uiState.animStart = 2;
								uiState.animEnd = 3;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.7;
								break;
							case 'phone':
								uiState.animStart = 23;
								uiState.animEnd = 24;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.7;
								break;
							default:
								break;
						}
						uiState.animLocation = '/';
						break;

					//navigating FROM about
					case '/about':
						//animLocation = where it's navigating FROM
						switch (uiState.animLocation) {
							case '/':
								uiState.animStart = 3;
								uiState.animEnd = 4;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.6;
								break;
							case 'learn':
								uiState.animStart = 4;
								uiState.animEnd = 5;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.5;
								break;
							case 'player':
								uiState.animStart = 14;
								uiState.animEnd = 15;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.6;
								break;
							case 'watcher':
								uiState.animStart = 7;
								uiState.animEnd = 8;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.5;
								break;
							case 'thankyou':
								uiState.animStart = 6;
								uiState.animEnd = 7;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.5;
								break;
							case 'phone':
								uiState.animStart = 27;
								uiState.animEnd = 28;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.4;
								break;
							default:
								break;
						}
						uiState.animLocation = 'about';
						break;
					//nav FROM watcher
					case '/watcher':
						//animLocation = where it's navigating FROM
						switch (uiState.animLocation) {
							case '/':
								uiState.animStart = 10;
								uiState.animEnd = 11;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.7;
								break;
							case 'learn':
								uiState.animStart = 8;
								uiState.animEnd = 9;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.6;
								break;
							case 'player':
								uiState.animStart = 11;
								uiState.animEnd = 12;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.6;
								break;
							case 'about':
								uiState.animStart = 7;
								uiState.animEnd = 8;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.5;
								break;
							case 'thankyou':
								uiState.animStart = 19;
								uiState.animEnd = 20;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.5;
								break;
							case 'phone':
								uiState.animStart = 20;
								uiState.animEnd = 21;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.5;
								break;
							default:
								break;
						}
						uiState.animLocation = 'watcher';
						break;
					//nav FROM player
					case '/player':
						//animLocation = where it's navigating FROM
						switch (uiState.animLocation) {
							case '/':
								uiState.animStart = 12;
								uiState.animEnd = 13;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.5;
								break;
							case 'learn':
								uiState.animStart = 16;
								uiState.animEnd = 17;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.5;
								break;
							case 'watcher':
								uiState.animStart = 11;
								uiState.animEnd = 12;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.6;
								break;
							case 'about':
								uiState.animStart = 14;
								uiState.animEnd = 15;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.6;
								break;
							case 'thankyou':
								uiState.animStart = 18;
								uiState.animEnd = 19;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.5;
								break;
							case 'phone':
								uiState.animStart = 25;
								uiState.animEnd = 26;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.3;
								break;
							default:
								break;
						}
						uiState.animLocation = 'player';
						break;

					//navigating FROM thankyou
					case '/thankyou':
						// console.log('thankyou', uiState.animLocation);
						switch (uiState.animLocation) {
							case '/':
								uiState.animStart = 2;
								uiState.animEnd = 3;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.7;
								break;
							case 'learn':
								uiState.animStart = 1;
								uiState.animEnd = 2;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.5;
								break;
							case 'about':
								uiState.animStart = 6;
								uiState.animEnd = 7;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.5;
								break;
							case 'watcher':
								uiState.animStart = 19;
								uiState.animEnd = 20;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.5;
								break;
							case 'player':
								uiState.animStart = 18;
								uiState.animEnd = 19;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.5;
								break;
							case 'phone':
								uiState.animStart = 21;
								uiState.animEnd = 22;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.7;
								break;
							default:
								break;
						}
						uiState.animLocation = 'thankyou';
						break;
					//navigating FROM phone
					case '/phone':
						// console.log('phone', uiState.animLocation);
						switch (uiState.animLocation) {
							case '/':
								uiState.animStart = 23;
								uiState.animEnd = 24;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.7;
								break;
							case 'learn':
								uiState.animStart = 28;
								uiState.animEnd = 29;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.5;
								break;
							case 'about':
								uiState.animStart = 27;
								uiState.animEnd = 28;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.4;
								break;
							case 'watcher':
								uiState.animStart = 20;
								uiState.animEnd = 21;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.5;
								break;
							case 'player':
								uiState.animStart = 25;
								uiState.animEnd = 26;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.3;
								break;
							case 'thankyou':
								uiState.animStart = 21;
								uiState.animEnd = 22;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.7;
								break;
							default:
								break;
						}
						uiState.animLocation = 'phone';
						break;
					//navigating FROM learn
					case '/learn':
						// console.log('learn');
						switch (uiState.animLocation) {
							case '/':
								uiState.animStart = 0;
								uiState.animEnd = 1;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.6;
								break;
							case 'about':
								uiState.animStart = 4;
								uiState.animEnd = 5;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.5;
								break;
							case 'watcher':
								uiState.animStart = 8;
								uiState.animEnd = 9;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.6;
								break;
							case 'player':
								uiState.animStart = 16;
								uiState.animEnd = 17;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.5;
								break;
							case 'thankyou':
								uiState.animStart = 1;
								uiState.animEnd = 2;
								uiState.animDirection = 'reverse';
								uiState.animSpeed = 0.5;
								break;
							case 'phone':
								uiState.animStart = 28;
								uiState.animEnd = 29;
								uiState.animDirection = 'normal';
								uiState.animSpeed = 0.5;
								break;
							default:
								break;
						}
						uiState.animLocation = 'learn';
						break;

					//404
					default:
						//404 page
						uiState.animStart = 0;
						uiState.animEnd = 1;
						uiState.animDirection = 'reverse';
						uiState.animSpeed = 1;
						uiState.animLocation = '404';
						break;
				}

				//
				// Animation Timeline Sequence Control
				//
				questSheet.sequence
					.play({
						range: [uiState.animStart, uiState.animEnd],
						rate: uiState.animSpeed,
						direction: uiState.animDirection,
						iterationCount: uiState.animLoop,
					})
					.then(() => {
						if (!uiState.homeShow && !uiState.optinShow) {
							if (uiState.animLocation === '/') {
								// if (uiState.mintPopShow == false) {
								// uiState.homeShow = true;
								// }
								// uiState.mapControls = false;
								// return;
							} else if (uiState.animLocation === 'learn') {
								uiState.optinShow = true;
								uiState.videoPlayerShow = false;
								uiState.animEndUpdate = true;
								uiState.mapControls = true;
							}
							console.log('Anim End', uiState.mapControls);
							uiState.mapControls = true;
						}
					});
			}
		});
		uiState.clickedState = false;
	}, [location]);
	return null;
};

export default AnimController;
