import React, { useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const Rotate = (props) => {
	const ref = useRef();

	useFrame((state, delta) => {
		switch (props.axis) {
			case 'x':
				ref.current.rotation.x += delta * props.speed;
				break;
			case 'y':
				ref.current.rotation.y += delta * props.speed;
				break;
			case 'z':
				ref.current.rotation.z += delta * props.speed;
				break;
			default:
				break;
		}

		// console.log('REF:', props.axis, props.speed);
	});
	return <group ref={ref} {...props} />;
};

export default Rotate;
