import { Perf } from 'r3f-perf';
import { StrictMode } from 'react';
import { Suspense, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { isMobile } from 'react-device-detect';
import { Canvas } from '@react-three/fiber';
import {
	CameraShake,
	MapControls,
	OrbitControls,
	AdaptiveEvents,
	AdaptiveDpr,
	PerformanceMonitor,
} from '@react-three/drei';
import { BrowserRouter, useSearchParams } from 'react-router-dom';
import Experience from './components/Experience';
import OptinForm from './components/OptinForm';
import HomeUi from './components/HomeUi';
import Footer from './components/Footer';
import Header from './components/Header';
import Drawer from './components/Drawer';
import Hamburger from './components/Hamburger';
import CookiePop from './components/CookiePop';
import { Loader } from './components/Loader';

import { CookiesProvider } from 'react-cookie';
import { useCookies } from 'react-cookie';
import './style.css';
import './loader.css';
import { uiState } from './states/ui-state';
import { useSnapshot } from 'valtio';

const root = ReactDOM.createRoot(document.querySelector('#root'));

function App() {
	const [objSearchParams, setSearchParams] = useSearchParams();
	const [cookies, setCookie] = useCookies(['aff']);

	const snap = useSnapshot(uiState);

	const [studioProps, setStudioProps] = useState(null);
	// set defaults for these props, they are passed into the studio UI to be set
	const [modalProps, setModalProps] = useState({
		opacity: 1,
		label: "I'M OVER 18 YEARS OLD",
	});

	//detect userAgent so we can load lower LOD (Level of Detail) scene and
	//animations for mobile devices, set global state
	useEffect(() => {
		uiState.isMobile = isMobile;
	}, [isMobile]);
	// console.log('isMobile', uiState.isMobile);

	useEffect(() => {
		if (!studioProps) return;
		const unsubscribe = studioProps.onValuesChange((objValues) => {
			setModalProps({ ...modalProps, ...objValues });
		});
		return unsubscribe;
	}, [studioProps]);

	// one time load on page start for cookies
	useEffect(() => {
		if (cookies && objSearchParams) {
			//look at cookie already set
			//console.log(cookies['aff']);
			const strAffiliate = objSearchParams.get('a');
			if (!cookies.aff || cookies.aff == 'null') {
				setCookie('aff', strAffiliate, {
					maxAge: 90 * 86400,
					secure: true,
					sameSite: true,
				});
			}
		}
	}, [objSearchParams, cookies]);

	return (
		<StrictMode>
			<CookiesProvider>
				<Suspense fallback={<Loader />}>
					{/* <Header /> */}
					<OptinForm />
					<HomeUi {...modalProps} />
					<Drawer />
					<Hamburger />
					<CookiePop />
					<Canvas
						id='canvas-container'
						dpr={[1, 2]}
						gl={{ preserveDrawingBuffer: true, antialias: false }}
						shadows
					>
						{/* <PerformanceMonitor /> */}
						{/* <Perf position='bottom-left' /> */}
						<AdaptiveDpr pixelated />
						<AdaptiveEvents />
						<MapControls
							enabled={uiState.mapControls}
							// autoRotate
							autoRotateSpeed={0.2}
							enablePan
							enableZoom
							// screenSpacePanning
							// minPolarAngle={Math.PI / 2}
							// maxPolarAngle={Math.PI / 3}
						/>
						{/* <OrbitControls
							enabled
							// autoRotate
							autoRotateSpeed={0.2}
							enablePan={false}
							enableZoom
							maxDistance={5}
							minDistance={0.1}
							minPolarAngle={Math.PI / 4}
							maxPolarAngle={Math.PI / 4}
						/> */}
						{/* <ScrollControls pages={5} enabled={snap.scrollControls} > */}
						<Experience setProps={setStudioProps} />
						{/* </ScrollControls> */}
						{/* <CameraShake
							maxYaw={0.1} // Max amount camera can yaw in either direction
							maxPitch={0.1} // Max amount camera can pitch in either direction
							maxRoll={0.1} // Max amount camera can roll in either direction
							yawFrequency={0.1} // Frequency of the the yaw rotation
							pitchFrequency={0.1} // Frequency of the pitch rotation
							rollFrequency={0.1} // Frequency of the roll rotation
							intensity={0.51} // initial intensity of the shake
							decayRate={0.45} // if decay = true this is the rate at which intensity will reduce at />
						/> */}
					</Canvas>
					<Footer />
				</Suspense>
			</CookiesProvider>
		</StrictMode>
	);
}

root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>
);
