import * as THREE from 'three';
import { useRef, useState, useEffect, Suspense } from 'react';
import {
	Environment,
	Float,
	ContactShadows,
	SpotLight,
	Center,
	Image,
	Text3D,
	useMatcapTexture,
	useTexture,
	useScroll,
} from '@react-three/drei';
import { uiState } from '../states/ui-state';
import { useSnapshot } from 'valtio';
import AnimController from './AnimController';
import { EquirectangularReflectionMapping } from 'three';

//TheatreJS Animation UI
import { getProject } from '@theatre/core';
import studio from '@theatre/studio';
import extension from '@theatre/r3f/dist/extension';
import {
	editable as e,
	RefreshSnapshot,
	SheetProvider,
	PerspectiveCamera,
} from '@theatre/r3f';
import QuestProjectState from '../states/state.json';
import { ThreeDButton } from './ThreeDButton';
import VideoTexture from './VideoTexture';
import { Logo } from './Logo';
import { SparklesComponent } from './Sparkles';
import { Office1 } from './Office1';
import { Office2 } from './Office2';
import { Loader } from './Loader';
import Hologram from './Hologram';
import Meteor from './Meteor';

const project = getProject('Quest Project', {
	state: QuestProjectState,
});
const QuestSheet = project.sheet('Quest Sheet');
// const buttonSheet = project.sheet('Button Sheet');
// const scrollSheet = project.sheet('Scroll Sheet');

if (process.env.NODE_ENV === 'development') {
	studio.initialize();
	studio.extend(extension);
	studio.ui.hide(false);
}

const EditableCamera = e(PerspectiveCamera, 'perspectiveCamera');
// const EditableScrollCamera = e(PerspectiveCamera, 'perspectiveCamera');
const EditableSpotLight = e(SpotLight, 'spotLight');

function iOS() {
	return (
		[
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod',
		].includes(navigator.platform) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes('Mac') && 'ontouchend' in document)
	);
}

export default function Experience(props) {
	const snap = useSnapshot(uiState);
	const [hovered] = useState(false);
	const [spotTarget1] = useState(() => new THREE.Object3D());
	const [spotTarget2] = useState(() => new THREE.Object3D());
	const [spotTarget3] = useState(() => new THREE.Object3D());
	const [deskLampTarget1] = useState(() => new THREE.Object3D());
	const [deskLampTarget2] = useState(() => new THREE.Object3D());

	const lookAtRef = useRef();
	const cameraTargetRef = useRef();

	//Setup Mouse hover
	useEffect(() => {
		if (hovered) document.body.style.cursor = 'pointer';
		return () => (document.body.style.cursor = 'auto');
	}, [hovered]);

	AnimController();

	//TODO this uses 3rd party CDN for this type of texture, fix it for production
	//flat gold
	const [matcapTextureGold] = useMatcapTexture(
		'71623B_ECDE8C_30250A_ABA69A',
		64
	);
	const [matcapTextureGlass] = useMatcapTexture(
		'293534_B2BFC5_738289_8A9AA7',
		64
	);
	const [matcapTextureChrome] = useMatcapTexture(
		'736655_D9D8D5_2F281F_B1AEAB',
		64
	);
	//chrome
	const [matcapTextureChrome2] = useMatcapTexture(
		'54584E_B1BAC5_818B91_A7ACA3',
		64
	);
	//soft dark purple
	const [matcapTexturePurple] = useMatcapTexture(
		'5C045C_BD0DBD_930493_A404A4',
		64
	);

	// const scroll = useScroll();
	//camera target to look at - Animate in TheatreJS
	// useFrame((state, delta) => {
	// 	state.camera.lookAt(lookAtRef.current.position);
	// 	state.camera.updateProjectionMatrix();
	// 	// scrollSheet.sequence.position = 10 * scroll.offset;
	// 	// scrollSheet.sequence.position =
	// 	//     val(scrollSheet.sequence.pointer.length) * scroll.offset;
	// 	return null;
	// });

	// const { size } = useThree();
	// useEffect(() => {
	// 	uiState.isMobile = size.width < 550;
	// }, []);

	//----================================================----\\

	const panoramaTexture = useTexture('./textures/moonless_golf_4k.jpg');
	// const panoramaTexture = useTexture('./textures/dark.jpg');
	panoramaTexture.mapping = EquirectangularReflectionMapping;

	const phoneVideo = './videos/phone-display.mp4';
	const jamboxVideo = './videos/jambox.mp4';
	const videoUrl1 = './videos/archivevideo.mp4';
	const videoUrl2 = './videos/computervideo.mp4';
	// const videoUrl3 = './videos/drone-sunset-vineyard.mp4';

	const shadowMapSize = 1024;
	const shadowBias = -0.0005;
	const normalBias = 1;

	return (
		<>
			<RefreshSnapshot />
			<SheetProvider sheet={QuestSheet}>
				<Environment preset='night' background={false} />
				<e.group theatreKey={'Quest / Scene'}>
					{/* <Suspense fallback={<Office1 />}> */}
					<Office1 />
					<Office2 visible={!uiState.isMobile} />
					{/* </Suspense> */}
				</e.group>
				<PerspectiveCamera
					theatreKey='Camera / Camera'
					makeDefault
					position={[0, 0, 16]}
					fov={75}
					lookAt={cameraTargetRef}
				/>
				<e.mesh
					ref={cameraTargetRef}
					theatreKey='Camera / Target'
					position={[0, 0, 0]}
					visible='editor'
				>
					<boxGeometry attach='geometry' />
					<meshPhongMaterial attach='material' color='red' />
				</e.mesh>
				<e.group theatreKey={'Sparkles'}>
					<SparklesComponent />
				</e.group>
				{/* LOGO TEXT */}
				<e.group theatreKey='Logo / Text'>
					<Logo />
				</e.group>
				{/* LIGHTS */}
				<e.ambientLight theatreKey='Lights / Area' />
				<e.group theatreKey='Lights / Group'>
					<e.group theatreKey='Lights / Spot Target1'>
						<primitive object={spotTarget1} />
					</e.group>
					<e.group theatreKey='Lights / Spot 1'>
						<SpotLight
							target={spotTarget1}
							castShadow
							distance={7.3}
							angle={0.3}
							penumbra={0.098}
							intensity={222}
							color={'#ff8a1f'}
							opacity={0.7}
							shadow-mapSize-height={shadowMapSize}
							shadow-mapSize-width={shadowMapSize}
							shadow-normalBias={normalBias}
							shadow-shadowBias={shadowBias}
						/>
					</e.group>
					<e.group theatreKey='Lights / Spot Target2'>
						<primitive object={spotTarget2} />
					</e.group>
					<e.group theatreKey='Lights / Spot 2'>
						<SpotLight
							target={spotTarget2}
							castShadow
							distance={7.3}
							angle={0.3}
							penumbra={0.098}
							intensity={55}
							color={'#ff8a1f'}
							opacity={0.7}
							shadow-mapSize-height={shadowMapSize}
							shadow-mapSize-width={shadowMapSize}
							shadow-normalBias={normalBias}
							shadow-shadowBias={shadowBias}
						/>
					</e.group>
					{/* <e.group theatreKey='Lights / Spot Target3'>
						<primitive object={spotTarget3} />
					</e.group>
					<e.group theatreKey='Lights / Spot 3'>
						<SpotLight
							target={spotTarget3}
							castShadow
							distance={7.3}
							angle={0.3}
							penumbra={0.098}
							intensity={55}
							color={'#ff8a1f'}
							opacity={0.7}
						/>
					</e.group> */}
					{/* <e.group theatreKey='Lights / Desk1 Target'>
						<primitive object={deskLampTarget1} />
					</e.group>
					<e.group theatreKey='Lights / Desk 1'>
						<SpotLight
							// theatreKey='Lights / Desk 1'
							target={deskLampTarget1}
							castShadow
							distance={1}
							angle={0.11}
							penumbra={1}
							// intensity={0.3}
							color={'#d0d401'}
							opacity={0.3}
							shadow-mapSize-height={512}
							shadow-mapSize-width={512}
							shadow-normalBias={normalBias}
							shadow-shadowBias={shadowBias}
						/>
					</e.group> */}
					{/* <e.group theatreKey='Lights / Desk2 Target'>
						<primitive object={deskLampTarget2} />
					</e.group>
					<e.group theatreKey='Lights / Desk 2'>
						<SpotLight
							target={deskLampTarget2}
							castShadow
							distance={1.43}
							angle={0.72}
							penumbra={0.22}
							intensity={33}
							color={'#d0d401'}
							// opacity={0.7}
						/>
					</e.group> */}
					{/* TV screen mask */}
					<e.group theatreKey={'Screens / TV Mask'}>
						<Image
							receiveShadow
							url={'/images/tvscreen.png'}
							transparent
							scale={(1.78, 1, 1)}
						></Image>
					</e.group>
					{/* old high school video */}
					<e.group theatreKey={'Screens / Old Video1'}>
						<VideoTexture
							url={videoUrl1}
							autoPlay={true}
							clickAction={'mute'}
							fallback={'./images/screen1-fallback.jpg'}
						/>
					</e.group>
					{/* Computer screen mask */}
					<e.group theatreKey={'Screens / Computer Mask'}>
						<Image
							receiveShadow
							url={'/images/comp-mask.png'}
							transparent
							scale={(1.78, 1, 1)}
						></Image>
					</e.group>
					{/* computer video */}
					<e.group theatreKey={'Screens / Computer Video1'}>
						<VideoTexture
							url={videoUrl2}
							autoPlay={true}
							fallback={'./images/screen1-fallback.jpg'}
						/>
					</e.group>
					{/* jambox video */}
					<e.group theatreKey={'Screens / Jambox'}>
						<VideoTexture
							url={jamboxVideo}
							autoPlay={false}
							clickAction={'play'}
							fallback={'./images/jamboxface.jpg'}
						/>
					</e.group>
					{/* phone screen mask */}
					<e.group theatreKey={'Screens / Phone Mask'}>
						<Image
							url={'/images/phone-video-frame.png'}
							transparent
						></Image>
					</e.group>
					{/* phone video */}
					<e.group theatreKey={'Screens / Phone'}>
						<VideoTexture
							url={phoneVideo}
							autoPlay={true}
							clickAction={'none'}
							fallback={'./images/phonescreen-fallback.png'}
						/>
					</e.group>
					{/* hologram video */}
					{/* <e.group theatreKey={'Holograms / Holo1'}> */}
					{/* <Hologram /> */}
					{/* <Meteor /> */}
					{/* </e.group> */}
				</e.group>
			</SheetProvider>
		</>
	);
}
