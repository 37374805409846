import React, { useState, useEffect } from 'react';
import { uiState } from '../states/ui-state.js';
import { useSnapshot } from 'valtio';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';

const Footer = () => {
	const [hovered, setHovered] = useState(false);
	const over = (e) => (e.stopPropagation(), setHovered(true));
	const out = () => setHovered(false); //Setup Mouse hover
	useEffect(() => {
		if (hovered) document.body.style.cursor = 'pointer';
		return () => (document.body.style.cursor = 'auto');
	}, [hovered]);
	const snap = useSnapshot(uiState);

	const navigate = useNavigate();

	const clickSound = '../audio/nav-click.mp3';
	const moveSound = '../audio/menu-start.mp3';

	const [play] = useSound(clickSound, { volume: 0.08 });
	const [play2] = useSound(moveSound, { playbackRate: 3.0, volume: 0.02 });

	const handleClick = (e, state) => {
		e.stopPropagation();
		uiState.animReady = true;
		uiState.frontShow = 'optin';
		// console.log('click1:', state, uiState.animLocation);
		if (state != uiState.animLocation) {
			// console.log('click2:', state, uiState.animLocation);
			navigate(state);
			play();
			play2();
		} else if (state == 'learn') {
			uiState.optinShow = true;
		}
	};

	return (
		<>
			<div className='footer-container' hidden={!uiState.footerShow}>
				<div className='footer'>
					<motion.p
						className='gradient-text'
						onClick={(e) => handleClick(e, 'watcher')}
						onPointerOver={over}
						onPointerOut={out}
						whileHover={{ scale: 1.1 }}
						whileTap={{ scale: 0.9 }}
					>
						WATCHER
					</motion.p>
					<motion.p
						className='gradient-text'
						onClick={(e) => handleClick(e, 'learn')}
						onPointerOver={over}
						onPointerOut={out}
						whileHover={{ scale: 1.1 }}
						whileTap={{ scale: 0.9 }}
					>
						LEARN
					</motion.p>

					<motion.div
						onClick={(e) => handleClick(e, '/')}
						onPointerOver={over}
						onPointerOut={out}
						whileHover={{ scale: 1.1 }}
						whileTap={{ scale: 0.9 }}
					>
						<img
							src='/images/logo-q.png'
							style={{ marginTop: '0px', width: '50px' }}
						/>
					</motion.div>
					<motion.p
						className='gradient-text'
						onClick={(e) => handleClick(e, 'about')}
						onPointerOver={over}
						onPointerOut={out}
						whileHover={{ scale: 1.1 }}
						whileTap={{ scale: 0.9 }}
					>
						ABOUT
					</motion.p>
					<motion.p
						className='gradient-text'
						onClick={(e) => handleClick(e, 'player')}
						onPointerOver={over}
						onPointerOut={out}
						whileHover={{ scale: 1.1 }}
						whileTap={{ scale: 0.9 }}
					>
						PLAYER
					</motion.p>
				</div>
			</div>
		</>
	);
};

export default Footer;
