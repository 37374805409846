import { proxy, ref } from 'valtio';
import { createRef } from 'react';

const uiState = proxy({
	optinShow: false,
	optinReveal: true,
	homeShow: false,
	homeReveal: true,
	footerShow: false,
	animLocation: '/',
	animTarget: 'load',
	animLoop: 1,
	back: 'about',
	forward: 'team',
	clickedState: 'false',
	showDrawer: false,
	cookiePopShow: false,
	isMobile: false,
	mapControls: true,
	videoPlayerShow: false,
	frontShow: 'optin',
	scrollControls: true,
	animEndUpdate: false,
	videoPlay: 'play',
	videoMuted: true,
	radioMuted: true,
	radioVideoPaused: true,
});

export const videoPlayerProxy = proxy({
	videoPlayerRef: ref(createRef()),
});

export { uiState };
