import * as THREE from 'three';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useAspect, useTexture, useVideoTexture } from '@react-three/drei';
import { uiState, videoPlayerProxy } from '../states/ui-state';
import useSound from 'use-sound';

const VideoTexture = (props) => {
	const autoPlay = props.autoPlay;
	const [videoVisible, setvideoVisible] = useState(true);
	const [hovered, setHovered] = useState(false);
	const over = (e) => (e.stopPropagation(), setHovered(true));
	const out = () => setHovered(false); //Setup Mouse hover
	useEffect(() => {
		if (hovered) document.body.style.cursor = 'pointer';
		return () => (document.body.style.cursor = 'auto');
	}, [hovered]);

	const oldRadioShow = '../audio/detectivesdilemma.mp3';

	const [play, { stop, isPlaying, pause }] = useSound(oldRadioShow, {
		loop: true,
		volume: 1.0,
	});

	const handleClick = (e, clickAction) => {
		console.log('video click', clickAction);
		e.stopPropagation();
		if (clickAction == 'mute') {
			video.muted = !video.muted;
		} else if (clickAction == 'play') {
			if (uiState.radioVideoPaused) {
				// console.log('radio1');
				void video.play();
				setvideoVisible(true);
			} else {
				// console.log('radio2');
				void video.pause();
				setvideoVisible(false);
			}
			uiState.radioVideoPaused = !uiState.radioVideoPaused;
			if (uiState.radioMuted && !isPlaying) {
				// console.log('radio3');
				play();
			} else {
				// console.log('radio4');
				pause();
			}
			uiState.radioMuted = !uiState.radioMuted;
		}
	};

	const [video] = useState(() =>
		Object.assign(document.createElement('video'), {
			src: props.url,
			crossOrigin: 'Anonymous',
			loop: true,
			muted: true,
		})
	);
	useEffect(() => {
		if (autoPlay) {
			void video.play();
			setvideoVisible(true);
		} else {
			void video.pause();
			setvideoVisible(false);
		}
	}, [video]);

	function VideoMaterial({ url }) {
		return (
			<meshStandardMaterial toneMapped={false} visible={videoVisible}>
				<videoTexture
					attach='map'
					args={[video]}
					encoding={THREE.sRGBEncoding}
				/>
			</meshStandardMaterial>
		);
	}

	function FallbackMaterial({ url }) {
		const texture = useTexture(url);
		return <meshBasicMaterial map={texture} toneMapped={false} />;
	}

	return (
		<>
			<group className='video'>
				<mesh
					onClick={(e) => handleClick(e, props.clickAction)}
					onPointerOver={over}
					onPointerOut={out}
				>
					<planeGeometry />
					<Suspense
						fallback={
							<FallbackMaterial url='./images/screen1-fallback.jpg' />
						}
					>
						<VideoMaterial url={props.url} />
					</Suspense>
				</mesh>
			</group>
		</>
	);
};

export default VideoTexture;
