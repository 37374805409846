import { Sparkles } from '@react-three/drei';
import { useMemo, Suspense } from 'react';

export const SparklesComponent = () => {
	const randomValue = useMemo(() => {
		return [Math.random(), Math.random(), Math.random()];
	}, []);
	return (
		<>
			<Sparkles
				count={11}
				speed={0.6}
				size={randomValue[0] * (44 - 1) + 1}
				noise={1}
				position={[0, 0.083, -0.17]}
				scale={[1, 2, 4]}
			/>
			<Sparkles
				count={11}
				speed={0.3}
				size={randomValue[1] * (22 - 1) + 1}
				noise={0.5}
				color={'#D923BA'}
				position={[0, 0.083, -0.17]}
				scale={[1, 2, 4]}
			/>
			<Sparkles
				count={22}
				speed={0.2}
				size={randomValue[2] * (22 - 1) + 1}
				noise={0.1}
				color={'#730255'}
				position={[0, 0.083, -0.17]}
				scale={[1, 2, 4]}
			/>
		</>
	);
};
