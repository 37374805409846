import {
	Box,
	Drawer,
	List,
	Divider,
	ListItem,
	ListItemButton,
	ListItemText,
} from '@mui/material';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import ForumIcon from '@mui/icons-material/Forum';
import PolicyIcon from '@mui/icons-material/Policy';
import GavelIcon from '@mui/icons-material/Gavel';
import CopyrightIcon from '@mui/icons-material/Copyright';
// import HowToRegIcon from '@mui/icons-material/HowToReg';
import CookieIcon from '@mui/icons-material/Cookie';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { useSnapshot } from 'valtio';
import { uiState } from '../states/ui-state';
import { useCookies } from 'react-cookie';
import { LocalPlay } from '@mui/icons-material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useNavigate } from 'react-router-dom';

export default function SideDrawer() {
	const [cookies] = useCookies(['aff']);
	const ss = useSnapshot(uiState);
	const privacyLink = 'https://www.thequest.game/privacy';
	const termsLink = 'https://www.thequest.game/terms';
	const discordLink = 'https://discord.com/invite/QVWeDJcBah';
	const facebookLink =
		'https://www.facebook.com/groups/722101449361627/permalink/722101456028293/?mibextid=W9rl1R';
	// const affiliatesLink = 'https://partners.nftwineclub.com/signup/affiliate';
	const affiliatesLink = 'http://watchers.thequest.game';
	const cookiesLink = 'https://www.thequest.game/cookies';

	const navigate = useNavigate();
	const handleClick = (e, state, id) => {
		e.stopPropagation();
		// if (state == 'mint') {
		// 	uiState.mintPopShow = true;
		// 	uiState.homeShow = false;
		// 	uiState.optinShow = false;
		// } else if (uiState.animLocation != 'nft') {
		// 	navigate(state);
		// }
		uiState.clickedState = true;
		uiState.showDrawer = false;
		// uiState.videoPlayerShow = false;
		uiState.frontShow = 'optin';
	};
	const links = () => (
		<Box
			sx={{
				width: ss.isMobile ? 180 : 250,
			}}
		>
			<Divider />
			<List>
				{/* <button
					className='button'
					onClick={() => {
						// toggle fullscreen
						if (document.fullscreenElement) {
							document.exitFullscreen();
						} else {
							document.documentElement.requestFullscreen();
						}
					}}
				></button> */}
				{/* <ListItem disablePadding>
                    <ListItemButton
                        component="a"
                        href={facebookLink}
                        target="_blank"
                    >
                        <FacebookIcon
                            style={{
                                paddingRight: '.6rem',
                                color: 'WhiteSmoke'
                            }}
                        />
                        <ListItemText
                            primary="FACEBOOK"
                            sx={{
                                '& .MuiTypography-root': {
                                    fontWeight: 600,
                                    fontFamily: 'Montserrat',

                                    background:
                                        '-webkit-linear-gradient(rgb(255, 255, 255), rgb(69, 69, 69))',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent'
                                }
                            }}
                        />
                    </ListItemButton>
                </ListItem> */}
				{/* <ListItem disablePadding>
                    <ListItemButton
                        component="a"
                        href={discordLink}
                        target="_blank"
                    >
                        <ForumIcon
                            style={{
                                paddingRight: '.6rem',
                                color: 'WhiteSmoke'
                            }}
                        />
                        <ListItemText
                            primary="DISCORD"
                            sx={{
                                '& .MuiTypography-root': {
                                    fontWeight: 600,
                                    fontFamily: 'Montserrat',

                                    background:
                                        '-webkit-linear-gradient(rgb(255, 255, 255), rgb(69, 69, 69))',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent'
                                }
                            }}
                        />
                    </ListItemButton>
                </ListItem> */}
				<ListItem disablePadding>
					<ListItemButton
						component='a'
						href={privacyLink}
						target='_blank'
					>
						<PolicyIcon
							style={{
								paddingRight: '.6rem',
								color: 'WhiteSmoke',
							}}
						/>
						<ListItemText
							primary='PRIVACY'
							sx={{
								'& .MuiTypography-root': {
									fontWeight: 600,
									fontFamily: 'Montserrat',

									background:
										'-webkit-linear-gradient(rgb(255, 255, 255), rgb(69, 69, 69))',
									WebkitBackgroundClip: 'text',
									WebkitTextFillColor: 'transparent',
								},
							}}
						/>
					</ListItemButton>
				</ListItem>
				<ListItem disablePadding>
					<ListItemButton
						component='a'
						href={termsLink}
						target='_blank'
					>
						<GavelIcon
							style={{
								paddingRight: '.6rem',
								color: 'WhiteSmoke',
							}}
						/>
						<ListItemText
							primary='TERMS'
							sx={{
								'& .MuiTypography-root': {
									fontWeight: 600,
									fontFamily: 'Montserrat',

									background:
										'-webkit-linear-gradient(rgb(255, 255, 255), rgb(69, 69, 69))',
									WebkitBackgroundClip: 'text',
									WebkitTextFillColor: 'transparent',
								},
							}}
						/>
					</ListItemButton>
				</ListItem>
				<ListItem disablePadding>
					<ListItemButton
						component='a'
						href={cookiesLink}
						target='_blank'
					>
						<CookieIcon
							style={{
								paddingRight: '.6rem',
								color: 'WhiteSmoke',
							}}
						/>
						<ListItemText
							primary='COOKIES'
							sx={{
								'& .MuiTypography-root': {
									fontWeight: 600,
									fontFamily: 'Montserrat',

									background:
										'-webkit-linear-gradient(rgb(255, 255, 255), rgb(69, 69, 69))',
									WebkitBackgroundClip: 'text',
									WebkitTextFillColor: 'transparent',
								},
							}}
						/>
					</ListItemButton>
				</ListItem>

				{/* <ListItem disablePadding>
                    <ListItemButton
                        component="a"
                        href={affiliatesLink}
                        target="_blank"
                    >
                        <HowToRegIcon
                            style={{
                                paddingRight: '.6rem',
                                color: 'WhiteSmoke'
                            }}
                        />
                        <ListItemText
                            primary="AFFILIATES"
                            sx={{
                                '& .MuiTypography-root': {
                                    fontWeight: 600,
                                    fontFamily: 'Montserrat',

                                    background:
                                        '-webkit-linear-gradient(rgb(255, 255, 255), rgb(69, 69, 69))',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent'
                                }
                            }}
                        />
                    </ListItemButton>
                </ListItem> */}
				<ListItem disablePadding>
					<CopyrightIcon
						style={{
							paddingTop: '1.1rem',
							paddingLeft: '1.1rem',
							paddingRight: '.3rem',
							paddingBottom: '.3rem',
							color: 'lightgray',
							width: '1.3rem',
						}}
					/>
					<ListItemText
						primary='2023 Watcher Society'
						sx={{
							'& .MuiTypography-root': {
								paddingTop: '1.01rem',
								paddingBottom: '.3rem',
								fontSize: '.7rem',
								fontWeight: 500,
								fontFamily: 'Montserrat',
								color: 'lightgray',
							},
						}}
					/>
				</ListItem>
				{cookies.aff !== 'null' && (
					<ListItem disablePadding>
						<LocalFireDepartmentIcon
							style={{
								paddingTop: '.1rem',
								paddingLeft: '1.1rem',
								fontSize: '1.3rem',
								color: 'lightgray',
							}}
						/>
						<p
							style={{
								paddingTop: '.1rem',
								paddingLeft: '.3rem',
								color: 'lightgray',
								fontSize: '.7rem',
							}}
						>
							ref: {cookies.aff}
						</p>
					</ListItem>
				)}
			</List>
		</Box>
	);

	return (
		<div>
			<Drawer
				anchor={'right'}
				open={ss.showDrawer}
				onClose={() => {
					uiState.showDrawer = false;
				}}
				sx={{
					'& .MuiPaper-root': {
						backgroundColor: 'rgba(30, 29, 29, 0.826)',
						boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
						borderTopLeftRadius: 11,
						borderBottomLeftRadius: 11,
						height: 'fit-content',
						top: '4rem',
						borderTop: `1px solid rgba(255, 255, 255, 0.3)`,
						borderLeft: `1px solid rgba(255, 255, 255, 0.3)`,
						borderBottom: `1px solid rgba(255, 255, 255, 0.3)`,
						backdropFilter: `blur(5px)`,
					},
				}}
			>
				{links()}
			</Drawer>
		</div>
	);
}
