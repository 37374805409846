import React, { useState, useEffect } from 'react';
import { uiState } from '../states/ui-state.js';
import { useSnapshot } from 'valtio';
import { motion } from 'framer-motion';
import { useCookies } from 'react-cookie';

const CookiePop = () => {
	const [cookies, setCookie] = useCookies(['cookieOptin']);
	const [hovered, setHovered] = useState(false);
	const over = (e) => (e.stopPropagation(), setHovered(true));
	const out = () => setHovered(false); //Setup Mouse hover
	useEffect(() => {
		if (hovered) document.body.style.cursor = 'pointer';
		return () => (document.body.style.cursor = 'auto');
	}, [hovered]);
	useSnapshot(uiState);

	const handleClick = (e) => {
		e.stopPropagation();
		uiState.cookiePopShow = false;
		if (cookies.cookieOptin != 'in') {
			setCookie('cookieOptin', 'in', {
				maxAge: 90 * 86400,
				secure: true,
				sameSite: true,
			});
		}
	};

	const openInNewTab = (url) => {
		window.open(url, '_blank', 'noreferrer');
	};

	if (cookies.cookieOptin == 'in') {
		uiState.cookiePopShow = false;
		setCookie('cookieOptin', 'in', {
			maxAge: 90 * 86400,
			secure: true,
			sameSite: true,
		});
	}

	return (
		<>
			<div className='cookie-pop' hidden={!uiState.cookiePopShow}>
				{/* <Backdrop cssName="backdrop" /> */}
				<div className='cookie-container'>
					<p>
						🍪 This website uses{' '}
						<span
							style={{
								textDecoration: 'underline',
								cursor: 'pointer',
							}}
							onClick={() =>
								openInNewTab(
									'https://www.thequest.game/privacy'
								)
							}
						>
							cookies
						</span>{' '}
						to improve your experience.
					</p>

					<motion.button
						whileHover={{ scale: 1.03 }}
						whileTap={{ scale: 0.99 }}
						onClick={(e) => handleClick(e)}
						style={{ width: '8rem', height: '2.5rem' }}
					>
						<p
							// className='gradient-text-gold'
							// className="gradient-text"
							style={{
								color: '#ccc',
								fontWeight: '600',
								fontSize: '14px',
								marginTop: '-.1rem',
							}}
						>
							GOT IT
						</p>
					</motion.button>
				</div>
			</div>
		</>
	);
};

export default CookiePop;
